import classNames from "classnames";
import React from "react";

interface IProps {
    closeModal: (event: React.MouseEvent<HTMLElement>) => void;
    isMobileNav: boolean;
}

interface IState {
    initCloseIconAni: boolean;
}

class DropdownCloseButton extends React.Component<IProps, IState> {
    public state: IState = {
        initCloseIconAni: false,
    };

    componentDidMount() {
        this.setState({
            initCloseIconAni: true,
        });
    }

    render() {
        const burgerIconClassName = classNames({
            "header__dropdown-close": true,
            "header__dropdown-close--active": this.state.initCloseIconAni,
        });

        return (
            <button
                onClick={(e) => {
                    this.props.closeModal(e);
                }}
                className={burgerIconClassName}
            >
                <span className="burger-icon" aria-hidden="true"></span>
                <span className="ada-screenreader-only">Close</span>
            </button>
        );
    }
}

export default DropdownCloseButton;
