import className from "classnames";
import React from "react";
import { connect } from "react-redux";

import { IOptionValues, IProduct } from "../../../models/catalogue.interfaces";
import {
    IProductCategoryID,
    isoImageURL,
    isoProductCategoryID,
} from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import {
    IPLCOptionPanel,
    IPLCProductCategorySelector,
    IPLCProductCategorySelectorOption,
} from "../models.interfaces";
import { getMostSimilarProduct } from "../product-similarity";
import { ISelectedCategories } from "../reducers.interfaces";
import { selectedCategoriesSelector } from "../selectors";

// Load styles
import "./PLCProductCategorySelectorOption.scss";

interface IOwnProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    selectorID: string;
    option: IPLCProductCategorySelectorOption;
    onOptionSelected: () => void;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    isChecked: boolean;
}

interface IDispatchProps {
    onSelectCategory: Dispatchers["setSelectedCategory"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

const BEM_NAME = "plc-configurator-category-selector-option";

class PLCProductCategorySelectorOptionComponent extends React.PureComponent<
    IProps,
    IState
> {
    /**
     * Check if selecting this option will result in a perfect product match or not. If
     * not, the option should be visually disabled (but still actually selectable).
     */
    private isDisabled(): boolean {
        const futureSelectedCategories = {
            ...this.props.selectedCategories,
            [this.props.selectorID]: this.props.option.category.id,
        };
        const result = getMostSimilarProduct({
            categorySelectors: this.props.categorySelectors,
            optionPanels: this.props.optionPanels,
            rootProducts: this.props.rootProducts,
            selectedCategories: futureSelectedCategories,
            optionValues: this.props.optionValues,
            previouslySelectedCategories: this.props.selectedCategories,
            previouslySelectedOptionValues: this.props.optionValues,
        });
        return !result || result.distance > 0;
    }

    private onSelectCategory(
        selectorID: string,
        categoryID: IProductCategoryID,
    ) {
        this.props.onOptionSelected();
        this.props.onSelectCategory({
            selectorID: selectorID,
            categoryID: categoryID,
            categorySelectors: this.props.categorySelectors,
            optionPanels: this.props.optionPanels,
        });
    }

    render() {
        const groupID = this.props.selectorID;
        const optionID = `${this.props.selectorID}-${this.props.option.category.id}`;
        const isDisabled = this.isDisabled();
        const listElementClass = className({
            [`${BEM_NAME}`]: true,
            [`${BEM_NAME}--selected`]: this.props.isChecked,
            [`${BEM_NAME}--faux-disabled`]: isDisabled,
        });
        const inputClass = className({
            [`${BEM_NAME}__input`]: true,
            [`${BEM_NAME}__input--faux-disabled`]: isDisabled,
        });
        const labelClass = className({
            [`${BEM_NAME}__label`]: true,
            [`${BEM_NAME}__label--faux-disabled`]: isDisabled,
        });
        return (
            <li
                className={listElementClass}
                onClick={() => {
                    if (!this.props.isChecked) {
                        this.onSelectCategory(
                            this.props.selectorID,
                            this.props.option.category.id,
                        );
                    }
                }}
            >
                {this.props.option.image && (
                    <img
                        src={isoImageURL.unwrap(this.props.option.image.url)}
                        alt={this.props.option.image.title}
                    />
                )}
                <input
                    type="radio"
                    className={inputClass}
                    id={optionID}
                    name={groupID}
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            this.onSelectCategory(
                                this.props.selectorID,
                                this.props.option.category.id,
                            );
                        }
                    }}
                    value={isoProductCategoryID.unwrap(
                        this.props.option.category.id,
                    )}
                    checked={this.props.isChecked}
                />
                <label
                    htmlFor={optionID}
                    className={labelClass}
                    aria-selected={false}
                >
                    {this.props.option.category.name}
                </label>
            </li>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    const selectedCategories = selectedCategoriesSelector(state);
    return {
        rootProducts: state.entities.rootProducts,
        selectedCategories: selectedCategories,
        optionValues: state.ui.optionValues,
        isChecked:
            selectedCategories[ownProps.selectorID] ===
            ownProps.option.category.id,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onSelectCategory: (...args) => {
            dispatchers.setSelectedCategory(...args);
        },
    };
};

export const PLCProductCategorySelectorOption = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PLCProductCategorySelectorOptionComponent);
