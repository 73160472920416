import className from "classnames";
import React from "react";
import { connect } from "react-redux";

import {
    IOptionSingleValue,
    IOptionValues,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { isoImageURL } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import {
    IPLCOptionPanel,
    IPLCOtherOptionSelector,
    IPLCProductCategorySelector,
} from "../models.interfaces";
import { getMostSimilarProduct } from "../product-similarity";
import { ISelectedCategories } from "../reducers.interfaces";
import { selectedCategoriesSelector } from "../selectors";
import { getOptionValueByIdx } from "../utils";
import { PLCInfoPanel } from "./PLCInfoPanel";

// Load styles
import "./PLCOtherOptionSelector.scss";

interface IOwnProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    selector: IPLCOtherOptionSelector;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    value: IOptionSingleValue | undefined;
}

interface IDispatchProps {
    onChange: Dispatchers["setOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

const BEM_NAME = "plc-other-option-selector";

class PLCOtherOptionSelectorComponent extends React.PureComponent<
    IProps,
    IState
> {
    /**
     * Check if selecting this option will result in a perfect product match or not. If
     * not, the option should be visually disabled (but still actually selectable).
     */
    private isDisabled(option: IOptionSingleValue): boolean {
        const futureOptionValues = {
            ...this.props.optionValues,
            [this.props.selector.value.attribute]: option,
        };
        const result = getMostSimilarProduct({
            categorySelectors: this.props.categorySelectors,
            optionPanels: this.props.optionPanels,
            rootProducts: this.props.rootProducts,
            selectedCategories: this.props.selectedCategories,
            optionValues: futureOptionValues,
            previouslySelectedCategories: this.props.selectedCategories,
            previouslySelectedOptionValues: this.props.optionValues,
        });
        return !result || result.distance > 0;
    }

    render() {
        const groupID = this.props.selector.id;
        return (
            <fieldset className={BEM_NAME}>
                <legend>{this.props.selector.value.name}</legend>
                <ul>
                    {this.props.selector.value.options.map((opt) => {
                        const optionID = `${groupID}-${opt.value}`
                            .replace(/[^a-zA-Z0-9-]/gi, "")
                            .toLowerCase();
                        const descriptionID = `${optionID}-description`;
                        const isChecked = this.props.value === opt.value;
                        const isDisabled = this.isDisabled(opt.value);
                        const listElementClass = className({
                            [`${BEM_NAME}__list-element--faux-disabled`]:
                                isDisabled,
                        });
                        const buttonClass = className({
                            [`${BEM_NAME}__option`]: true,
                            [`${BEM_NAME}__option--selected`]: isChecked,
                            [`${BEM_NAME}__option--faux-disabled`]: isDisabled,
                        });
                        const inputClass = className({
                            [`${BEM_NAME}__input`]: true,
                            [`${BEM_NAME}__input--faux-disabled`]: isDisabled,
                        });
                        const labelClass = className({
                            [`${BEM_NAME}__label`]: true,
                            [`${BEM_NAME}__label--faux-disabled`]: isDisabled,
                        });
                        return (
                            <li key={opt.name} className={listElementClass}>
                                <div
                                    className={buttonClass}
                                    onClick={() => {
                                        if (!isChecked) {
                                            this.props.onChange(
                                                "",
                                                this.props.selector.value
                                                    .attribute,
                                                0,
                                                1,
                                                opt.value,
                                            );
                                        }
                                    }}
                                >
                                    <div className={`${BEM_NAME}__icon`}>
                                        {opt.image && (
                                            <img
                                                src={isoImageURL.unwrap(
                                                    opt.image.url,
                                                )}
                                                alt={opt.name}
                                            />
                                        )}
                                    </div>
                                    <input
                                        type="radio"
                                        className={inputClass}
                                        id={optionID}
                                        name={groupID}
                                        onChange={(e) => {
                                            if (e.currentTarget.checked) {
                                                this.props.onChange(
                                                    "",
                                                    this.props.selector.value
                                                        .attribute,
                                                    0,
                                                    1,
                                                    opt.value,
                                                );
                                            }
                                        }}
                                        checked={this.props.value === opt.value}
                                        value={opt.value}
                                    />
                                    <div>
                                        <div>
                                            <label
                                                htmlFor={optionID}
                                                className={labelClass}
                                                aria-selected={false}
                                                aria-describedby={descriptionID}
                                            >
                                                {opt.name}
                                            </label>
                                        </div>
                                        <p
                                            id={descriptionID}
                                            className={`${BEM_NAME}__description`}
                                        >
                                            {opt.description}
                                        </p>
                                    </div>
                                </div>
                                <PLCInfoPanel
                                    buttonText={"Learn More"}
                                    content={opt.help_content}
                                />
                            </li>
                        );
                    })}
                </ul>
            </fieldset>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProducts: state.entities.rootProducts,
        selectedCategories: selectedCategoriesSelector(state),
        optionValues: state.ui.optionValues,
        value: getOptionValueByIdx(
            state.ui.optionValues[ownProps.selector.value.attribute],
            0,
        ),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onChange: (...args) => {
            dispatchers.setOptionValue(...args);
        },
    };
};

export const PLCOtherOptionSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PLCOtherOptionSelectorComponent);
