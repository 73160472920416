import memoize from "memoize-one";
import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { connect } from "react-redux";
import { t } from "ttag";

import iconCheckCircleTeal from "../../../../img/icons/check-circle-teal.svg";
import iconXCircleRed from "../../../../img/icons/x-circle-red.svg";
import { IOptionValues, IProduct } from "../../../models/catalogue.interfaces";
import { IProductID } from "../../../models/nominals";
import { trapFocus } from "../../../utils/keyboardFocus";
import { PointDifferenceRow } from "../../../utils/similarity";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { MOBILE_WIDTH_THRESHOLD_ALTERNATIVE } from "../constants";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import {
    IPLCOptionPanel,
    IPLCProductCategorySelector,
} from "../models.interfaces";
import { getMostSimilarProduct } from "../product-similarity";
import { ISelectedCategories } from "../reducers.interfaces";
import {
    previouslySelectedCategoriesSelector,
    selectedCategoriesSelector,
} from "../selectors";

// Load styles
import "./PLCProductMatchModal.scss";

interface IOwnProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    modalPosition: number | undefined;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    previouslySelectedCategories: ISelectedCategories;
    previouslySelectedOptionValues: IOptionValues;
}

interface IDispatchProps {
    onUndoSelection: Dispatchers["undoSelection"];
    onSetSelectedVariant: Dispatchers["setSelectedVariant"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    contentHeight: number | undefined;
}

/**
 * Return a map of product variant IDs to the ID of the root product
 */
const getRootProductLookupTable = (
    rootProducts: IProduct[],
): Map<IProductID, IProductID> => {
    const table = new Map<IProductID, IProductID>();
    rootProducts.forEach((root) => {
        root.children.forEach((child) => {
            table.set(child.id, root.id);
        });
    });
    return table;
};

const BEM_NAME = "plc-product-match-modal";

const DifferenceRow = (props: { row: PointDifferenceRow }) => {
    return (
        <tr>
            <td>
                <span className={`${BEM_NAME}__dimension-name`}>
                    {props.row.dimensionName}
                </span>
                <span
                    className={`${BEM_NAME}__dimension-value ${BEM_NAME}__dimension-value--removing`}
                >
                    {props.row.aDescr}
                </span>
            </td>
            <td>
                <span className={`${BEM_NAME}__dimension-name`}>
                    {props.row.dimensionName}
                </span>
                <span
                    className={`${BEM_NAME}__dimension-value ${BEM_NAME}__dimension-value--adding`}
                >
                    {props.row.bDescr}
                </span>
            </td>
        </tr>
    );
};

class PLCProductMatchModalComponent extends React.PureComponent<
    IProps,
    IState
> {
    private contentElem: HTMLElement | undefined | null;
    private modalElem: HTMLElement | undefined | null;

    public state: IState = {
        contentHeight: 0,
    };

    private readonly getRootProductLookupTable = memoize(
        getRootProductLookupTable,
    );

    private readonly onAfterModalOpen = (): void => {
        if (!this.modalElem) {
            return;
        }
        this.setState({
            contentHeight: this.contentElem?.getBoundingClientRect().height,
        });
        trapFocus(this.modalElem);
    };

    private readonly onCancel = (): void => {
        this.props.onUndoSelection();
    };

    private readonly onConfirm = (closestMatchingVariant: IProduct): void => {
        const rootProductIDs = this.getRootProductLookupTable(
            this.props.rootProducts,
        );
        const rootProductID = rootProductIDs.get(closestMatchingVariant.id);
        const rootProduct = this.props.rootProducts.find(
            (root) => root.id === rootProductID,
        );
        if (!rootProduct) {
            throw new Error("Root product for variant is missing!");
        }
        this.props.onSetSelectedVariant(
            rootProduct,
            closestMatchingVariant,
            this.props.categorySelectors,
        );
    };

    private readonly isMobileWidth = () => {
        return window.innerWidth < MOBILE_WIDTH_THRESHOLD_ALTERNATIVE;
    };

    render() {
        const match = getMostSimilarProduct({
            categorySelectors: this.props.categorySelectors,
            optionPanels: this.props.optionPanels,
            rootProducts: this.props.rootProducts,
            selectedCategories: this.props.selectedCategories,
            optionValues: this.props.optionValues,
            previouslySelectedCategories:
                this.props.previouslySelectedCategories,
            previouslySelectedOptionValues:
                this.props.previouslySelectedOptionValues,
        });
        if (match === null) {
            return null;
        }
        const matchIsImperfect = match.distance > 0;
        const visibleDiffRows = match.difference.filter((r) => r.showInDiff);
        const hasVisibleDiff = visibleDiffRows.length > 0;

        // If the match is imperfect, but none of the differences are supposed to be shown in the diff
        // table, then just silently select it without opening the modal.
        if (matchIsImperfect && !hasVisibleDiff) {
            this.onConfirm(match.bestProduct);
        }

        const topPos =
            this.props.modalPosition && this.state.contentHeight
                ? this.props.modalPosition - this.state.contentHeight
                : 0;

        return (
            <Modal
                aria={{ modal: true }}
                className={`${BEM_NAME}__modal`}
                overlayClassName={`${BEM_NAME}__overlay`}
                contentRef={(r) => {
                    this.modalElem = r;
                }}
                isOpen={matchIsImperfect && hasVisibleDiff}
                onAfterOpen={this.onAfterModalOpen}
                shouldReturnFocusAfterClose={false}
                bodyOpenClassName="match-modal-open"
                role="dialog"
                style={{
                    content: {
                        top: this.isMobileWidth() ? "auto" : `${topPos}px`,
                        bottom: this.isMobileWidth() ? "0px" : "auto",
                        right: "0",
                        padding: "0",
                        position: "absolute",
                        overflow: "auto",
                    },
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                    },
                }}
            >
                <div
                    ref={(r) => {
                        this.contentElem = r;
                    }}
                    className={`${BEM_NAME}__content`}
                >
                    <header>
                        <p>
                            {match.betterProductExistsButIsUnavailable
                                ? t`Out of Stock`
                                : t`Changes Required`}
                        </p>
                        <p>
                            {match.betterProductExistsButIsUnavailable
                                ? t`The options you've selected aren't currently in-stock, but a similar configuration is.`
                                : t`The options you've selected aren't available, but a similar configuration is.`}
                        </p>
                    </header>
                    <div className="table-holder">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <SVG src={iconXCircleRed} />
                                        {t`Removing`}
                                    </th>
                                    <th>
                                        <SVG src={iconCheckCircleTeal} />
                                        {t`Adding closest match`}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {visibleDiffRows.map((row) => (
                                    <DifferenceRow
                                        key={row.dimensionName}
                                        row={row}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={`${BEM_NAME}__buttons`}>
                        <button onClick={this.onCancel}>{t`Cancel`}</button>
                        <button
                            onClick={() => {
                                this.onConfirm(match.bestProduct);
                            }}
                        >
                            {t`Confirm`}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProducts: state.entities.rootProducts,
        selectedCategories: selectedCategoriesSelector(state),
        optionValues: state.ui.optionValues,
        previouslySelectedCategories:
            previouslySelectedCategoriesSelector(state),
        previouslySelectedOptionValues:
            state.ui.previousSelectionState?.optionValues || {},
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onUndoSelection: () => {
            dispatchers.undoSelection();
        },
        onSetSelectedVariant: (...args) => {
            dispatchers.setSelectedVariant(...args);
        },
    };
};

export const PLCProductMatchModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PLCProductMatchModalComponent);
