import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";

import {
    TDispatchMapper,
    TStateMapper,
} from "../../../apps/reducers.interfaces";
import { IMiles, isoMiles } from "../../../models/nominals";
import { Dispatchers } from "../dispatchers";
import { storeFilterSelector } from "../selectors";

interface IOwnProps {
    selectProps?: React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    >;
}

interface IReduxProps {
    currentValue: IMiles;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class FilterDistanceSelectContainer extends React.Component<IProps, IState> {
    render() {
        const steps = [10, 20, 30, 40, 50, 75, 100];
        return (
            <select
                {...this.props.selectProps}
                value={isoMiles.unwrap(this.props.currentValue)}
                onChange={(e) => {
                    e.preventDefault();
                    this.props.dispatchers.updateStoreFilters({
                        distance: isoMiles.wrap(
                            parseInt(e.currentTarget.value, 10),
                        ),
                    });
                }}
            >
                {steps.map((step) => (
                    <option key={step} value={step}>
                        {t`${step} Miles`}
                    </option>
                ))}
            </select>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        currentValue: storeFilterSelector(rootState).distance,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const FilterDistanceSelect = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterDistanceSelectContainer);
