import React from "react";
import { ChatLink } from "tsi-common-react/src/apps/chat/ChatLink";
import { FinancingPlan } from "tsi-common-react/src/models/financing";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";

import { BasketFinePrint } from "./BasketFinePrint";

interface IProps {
    plans: FinancingPlan[];
    isMobile: boolean;
    cartID?: string;
}

export const BasketSidebarContent = (_props: IProps) => {
    const phoneLink = (
        <a href={getPageSetting("support-website-phone-number-link")}>
            {getPageSetting("support-website-phone-number-display")}
        </a>
    );
    let finePrint: JSX.Element | null = null;
    if (_props.isMobile) {
        finePrint = <BasketFinePrint plans={_props.plans} />;
    }

    return (
        <div>
            <div className="basket-summary__help">
                <h3>Questions?</h3>
                <div>
                    <p>
                        <ChatLink
                            className="basket-summary__help--link"
                            chatOfflineLink={urls.pageURL("customer-service")}
                        />
                        {" or "} <span>Call {phoneLink}</span>
                        <br />
                        {" and reference "}
                        <strong>{_props.cartID}</strong>
                    </p>
                </div>
            </div>
            {finePrint}
        </div>
    );
};
