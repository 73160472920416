import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";

import iconQuestionMarkCircleTeal from "../../../../img/icons/question-mark-circle--teal.svg";
import {
    IPLCOptionPanel,
    IPLCProductCategorySelector,
} from "../models.interfaces";
import { PLCInfoPanel } from "./PLCInfoPanel";
import { PLCProductCategorySelectorOption } from "./PLCProductCategorySelectorOption";

// Load styles
import "./PLCProductCategorySelector.scss";

interface IProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    selector: IPLCProductCategorySelector;
}

interface IState {
    isSelected: boolean;
}

const BEM_NAME = "plc-configurator-category-selector";

export class PLCProductCategorySelector extends React.PureComponent<
    IProps,
    IState
> {
    public state: IState = {
        isSelected: false,
    };

    private onOptionSelected() {
        this.setState({
            isSelected: true,
        });
    }

    render() {
        const selectorClasses = classNames({
            [`${BEM_NAME}`]: true,
            [`${BEM_NAME}--selected`]: this.state.isSelected,
        });

        return (
            <fieldset className={selectorClasses}>
                <legend>
                    {this.props.selector.value.name}
                    &nbsp;
                    <PLCInfoPanel
                        buttonText={
                            <sup>
                                <SVG
                                    aria-hidden="true"
                                    src={iconQuestionMarkCircleTeal}
                                />
                            </sup>
                        }
                        content={this.props.selector.value.learn_more}
                    />
                </legend>
                <ul>
                    {this.props.selector.value.options.map((option) => (
                        <PLCProductCategorySelectorOption
                            key={`${option.category.id}`}
                            categorySelectors={this.props.categorySelectors}
                            optionPanels={this.props.optionPanels}
                            selectorID={this.props.selector.id}
                            option={option}
                            onOptionSelected={this.onOptionSelected.bind(this)}
                        />
                    ))}
                </ul>
            </fieldset>
        );
    }
}
