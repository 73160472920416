import React from "react";
import SVG from "react-inlinesvg";
import ReactTooltip from "react-tooltip";
import iconQuestionMarkCircleFilled from "tsi-common-react/img/icons/question-mark-circle-filled.svg";
import { trapFocus } from "tsi-common-react/src/utils/keyboardFocus";
import { t } from "ttag";

interface IProps {}

interface IState {
    phoneNumber: string;
    basketId: string;
    isSmallScreen: boolean;
    isKeyDown: boolean;
    isTooltipOpen: boolean;
}

export class CartReferenceNumber extends React.Component<IProps, IState> {
    public SMALL_SCREEN_WIDTH_THRESHOLD = 1366;

    public state: IState = {
        phoneNumber: "",
        basketId: "",
        isSmallScreen: false,
        isKeyDown: false,
        isTooltipOpen: false,
    };

    private readonly checkScreenSize = () => {
        this.setState({
            isSmallScreen:
                window.innerWidth < this.SMALL_SCREEN_WIDTH_THRESHOLD,
        });
    };

    componentDidMount() {
        const cartRef = document.querySelector<HTMLElement>(
            ".checkout-header__header-cart-ref",
        );
        if (cartRef) {
            const phoneNumber: string = cartRef.dataset.phoneNumber as string;
            const basketId: string = cartRef.dataset.encodedBasketId as string;

            this.setState({
                phoneNumber: phoneNumber,
                basketId: basketId,
            });
        }

        this.checkScreenSize();
        window.addEventListener("resize", () => {
            this.checkScreenSize();
        });
    }

    componentDidUpdate() {
        const tooltip =
            this.state.isKeyDown &&
            document.querySelector(".basket-lines__tooltip.tooltip");
        if (tooltip) {
            tooltip.classList.add("key-down");
        }
        const activeTooltip = document.querySelector<HTMLElement>(
            ".basket-lines__tooltip.tooltip.show",
        );
        if (activeTooltip) {
            const activeCloseButton = activeTooltip.querySelector("button");
            if (activeCloseButton) {
                activeCloseButton.focus();
            }
            trapFocus(activeTooltip);
        }
    }

    render() {
        const tipId = "basket-lines__tooltip-cart-ref";
        return (
            <div className="basket-header__header-cart-ref">
                <span className="basket-lines__header-cart-ref--first-line">
                    {t`Cart Reference Number: `}
                </span>
                <span className="basket-lines__header-cart-ref--second-line tooltip-container">
                    <span
                        className="checkout-header__header-cart-ref--bold"
                        aria-label={t`Cart Reference Number`}
                    >
                        {this.state.basketId}
                    </span>
                    <div
                        aria-describedby={tipId}
                        aria-expanded={this.state.isTooltipOpen}
                        className="basket-lines__header-cart-ref-icon-container"
                        data-for={tipId}
                        data-tip={true}
                        tabIndex={0}
                    >
                        <SVG
                            aria-hidden="true"
                            className="basket-lines__header-cart-ref-icon"
                            src={iconQuestionMarkCircleFilled}
                            title={t`Questions Icon`}
                        />
                        <span className="ada-screenreader-only">
                            {t`Cart Reference Number Explanation`}
                        </span>
                    </div>
                    <ReactTooltip
                        aria-label={t`Question about your order?`}
                        class="basket-lines__tooltip tooltip"
                        event={"mouseover focus"}
                        eventOff="scroll mousewheel mouseleave mouseout blur"
                        id={tipId}
                        place={this.state.isSmallScreen ? "left" : "bottom"}
                        role="tooltip"
                        isCapture={true}
                    >
                        <strong>
                            {t`Have an issue completing your order?`}
                        </strong>
                        <br />
                        {t`Call ${this.state.phoneNumber} and provide your cart ID for assistance.`}
                    </ReactTooltip>
                </span>
            </div>
        );
    }
}
