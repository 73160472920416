import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll,
} from "body-scroll-lock";
import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { t } from "ttag";

import iconArrowLeftBlueAlternate from "../../../../img/icons/arrow-left-blue--alternate.svg";
import iconArrowRightBlue from "../../../../img/icons/arrow_right_blue.svg";
import { IAPIPrice } from "../../../models/prices.interfaces";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import { format } from "../../../utils/format";
import { focusElement, trapFocus } from "../../../utils/keyboardFocus";
import { slugify } from "../../../utils/text";
import { TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import {
    IPLCOptionPanel,
    IPLCProductCategorySelector,
} from "../models.interfaces";
import { upgradedVariantPriceSelector } from "../selectors";
import { PLCProductOptionSelector } from "./PLCProductOptionSelector";
import { PLCProductOptionSelectorCurrentValue } from "./PLCProductOptionSelectorCurrentValue";

// Load styles
import "./PLCOptionPanel.scss";

interface IOwnProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    panel: IPLCOptionPanel;
}

interface IReduxProps {
    price: IAPIPrice | null;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isOpen: boolean;
}

const BEM_NAME = "plc-option-panel";

class PLCOptionPanelComponent extends React.PureComponent<IProps, IState> {
    private readonly triggerElem = React.createRef<HTMLButtonElement>();
    private readonly closeBtnElem = React.createRef<HTMLButtonElement>();
    private modalElem: HTMLDivElement | undefined | null;

    public state: IState = {
        isOpen: false,
    };

    private readonly onOpenModal = () => {
        this.setState(
            {
                isOpen: true,
            },
            () => {
                disableBodyScroll(this.modalElem as HTMLElement);
                this.onAfterModalOpen();
            },
        );
    };

    private readonly onRequestClose = () => {
        // `enableBodyScroll` should run before setState `isPanelOpen: false`,
        // so inline style `position: fixed` from `<body>` will be removed first before removing `.configurator-panel-open` from `<body>`.
        // This way can avoid the issue of the page jumping to the top when panel is opened/closed
        enableBodyScroll(this.modalElem as HTMLElement);

        this.setState(
            {
                isOpen: false,
            },
            () => {
                if (this.triggerElem.current) {
                    focusElement(this.triggerElem.current);
                }
            },
        );
    };

    private readonly onAfterModalOpen = () => {
        if (!this.triggerElem.current || !this.modalElem) {
            return;
        }

        if (this.closeBtnElem.current) {
            focusElement(this.closeBtnElem.current);
        }
        trapFocus(this.modalElem);
    };

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    render() {
        const panelName = this.props.panel.value.short_name;
        const panelNameSlugged = slugify(panelName);
        const price = this.props.price
            ? getProductPrice(this.props.price, {
                  priceType: PriceType.COSMETIC_EXCL_TAX,
                  includePostDiscountAddons: true,
                  quantity: this.props.price.quantity,
              })
            : null;
        return (
            <div className={BEM_NAME}>
                <div className={`${BEM_NAME}__trigger`}>
                    <div className={`${BEM_NAME}__label`}>{panelName}</div>
                    <button onClick={this.onOpenModal} ref={this.triggerElem}>
                        <PLCProductOptionSelectorCurrentValue
                            selectors={this.props.panel.value.selectors}
                        />
                        <SVG src={iconArrowRightBlue} />
                    </button>
                </div>
                <CSSTransition
                    key="plc-option-panel"
                    in={this.state.isOpen}
                    timeout={100}
                    classNames="plc-option-panel__window-"
                    unmountOnExit={true}
                >
                    <div
                        aria-modal="true"
                        className={`${BEM_NAME}__window`}
                        ref={(r) => {
                            this.modalElem = r;
                        }}
                        role="dialog"
                    >
                        <header>
                            <button
                                aria-label={panelName}
                                onClick={this.onRequestClose}
                                ref={this.closeBtnElem}
                            >
                                <SVG src={iconArrowLeftBlueAlternate} />
                                {panelName}
                            </button>
                            <div className={`${BEM_NAME}__current-price`}>
                                {price ? format.money(price) : ""}
                            </div>
                        </header>
                        <div
                            className={`${BEM_NAME}__content ${BEM_NAME}__content--${panelNameSlugged}`}
                        >
                            {this.props.panel.value.selectors.map(
                                (selector) => (
                                    <PLCProductOptionSelector
                                        key={selector.id}
                                        categorySelectors={
                                            this.props.categorySelectors
                                        }
                                        optionPanels={this.props.optionPanels}
                                        selector={selector}
                                    />
                                ),
                            )}
                        </div>
                        <div
                            className={`${BEM_NAME}__content ${BEM_NAME}__content--button`}
                        >
                            <button
                                className={`${BEM_NAME}__done-button button button--primary`}
                                aria-label={t`Done`}
                                onClick={this.onRequestClose}
                            >
                                {t`Done`}
                            </button>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        price: upgradedVariantPriceSelector(state),
        // Direct Props
        ...ownProps,
    };
};

export const PLCOptionPanel = connect(mapStateToProps)(PLCOptionPanelComponent);
