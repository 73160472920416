import classNames from "classnames";
import React from "react";
import { Image } from "tsi-common-react/src/common/Image";
import { IConcreteBundle } from "tsi-common-react/src/models/catalogue.interfaces";
import {
    IImageURL,
    IProductAPIURL,
    IWebPageURL,
    isoWebPageURL,
} from "tsi-common-react/src/models/nominals";
import format from "tsi-common-react/src/utils/format";
import { t } from "ttag";

interface IProps {
    isBundle: boolean;
    bundle: IConcreteBundle;
    imagePath: IImageURL;
    imageAlt: string;
    ctaPath?: IWebPageURL | null;
    ctaCopy?: string;
    buyIsDisabled: boolean;
    onAddToBasket: (productURL: IProductAPIURL) => void;
}

interface IState {}

// TODO: Determine if this is a safe assumption re: targeting the appropriate suggested product
// i.e. Is there a use case where there would be more than one suggested product in a bundle?
export class UpsellModalSlide extends React.Component<IProps, IState> {
    private readonly onAddToBasket = async (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        this.props.onAddToBasket(this.props.bundle.suggested_products[0].url);
    };

    private buildPrice(priceExcTax: string, priceRetail: string) {
        if (!priceRetail || priceRetail === priceExcTax) {
            return (
                <div className="upsell-modal-slide__price">
                    {format.money(priceExcTax)}
                </div>
            );
        }
        return (
            <div className="upsell-modal-slide__price">
                <span className="ada-screenreader-only">
                    {t`Original price:`}
                </span>
                <span className="upsell-modal-slide__price--strike">
                    {format.money(priceRetail)}
                </span>
                <span className="ada-screenreader-only">
                    {t`Current price:`}
                </span>
                <span className="upsell-modal-slide__price--highlight">
                    {format.money(priceExcTax)}
                </span>
            </div>
        );
    }

    private renderTitle() {
        if (!this.props.isBundle) {
            return null;
        }
        const product = this.props.bundle.suggested_products[0];
        if (!product || !product.price.cosmetic_excl_tax) {
            return null;
        }
        const productTitle = product.parent
            ? product.parent.title
            : product.title;
        return (
            <div className="upsell-modal-slide__title">
                {this.buildPrice(
                    product.price.cosmetic_excl_tax,
                    product.price.retail || "",
                )}
                <div className="upsell-modal-slide__product-title">
                    {productTitle}
                </div>
            </div>
        );
    }

    private renderCTA() {
        if (this.props.isBundle) {
            let addToBasketButtonText: string = t`Add to Cart`;

            const product = this.props.bundle.suggested_products[0];
            if (
                product &&
                product.attributes &&
                product.attributes.preorder &&
                product.attributes.preorder.value
            ) {
                addToBasketButtonText = t`Pre-order`;
            }

            return (
                <button
                    className="button upsell-modal-slide__cta upsell-modal-slide__cta--bundle al-upsell-modal__button--add-to-cart"
                    disabled={this.props.buyIsDisabled}
                    onClick={this.onAddToBasket}
                >
                    {addToBasketButtonText}
                </button>
            );
        }
        return (
            <a
                className="button button--ghost upsell-modal-slide__cta upsell-modal-slide__cta--misc"
                href={
                    this.props.ctaPath
                        ? isoWebPageURL.unwrap(this.props.ctaPath)
                        : undefined
                }
            >
                {this.props.ctaCopy}
            </a>
        );
    }

    // TODO accessibility: remove text from images when refactoring this component
    render() {
        const imgClasses = classNames({
            "upsell-modal-slide__image": true,
            "upsell-modal-slide__image--bundle": this.props.isBundle,
        });
        return (
            <div
                className="upsell-modal-slide"
                role="group"
                aria-label="Power base model"
            >
                <div className="upsell-modal-slide__cta-container">
                    {this.renderTitle()}
                    {/*
                        Looks like image loading is completed after Flickty is mounted.
                        This loaded image by lazyload have no size, which throws off cell positions in the Flickity so turning off lazyload to fix this issue.
                    */}
                    <Image
                        lazy={false}
                        className={imgClasses}
                        src={this.props.imagePath}
                        alt={this.props.imageAlt}
                    />
                    {this.renderCTA()}
                </div>
            </div>
        );
    }
}

export default UpsellModalSlide;
