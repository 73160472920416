import { IQueryParams, searchStores } from "../../api/retail";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "../../models/location.interfaces";
import { isLatLngLocation, isZipLocation } from "../../utils/guards";

export type StoreLocationProvider = (
    location: ILocation,
    extraFilters?: Partial<IQueryParams>,
) => Promise<IRetailStoreWithDistance[]>;

export const buildBaseQuery = (location: ILocation): IQueryParams | null => {
    if (isLatLngLocation(location)) {
        return {
            origLat: location.lat,
            origLng: location.lng,
        };
    }
    if (isZipLocation(location)) {
        return {
            q: location.zip,
        };
    }
    return null;
};

export class Loaders {
    public readonly loadClosestStores: StoreLocationProvider = async (
        location,
        extraFilters,
    ) => {
        const baseQuery = buildBaseQuery(location);
        if (!baseQuery) {
            return [];
        }
        const stores = await searchStores({
            ...baseQuery,
            ...extraFilters,
        });
        return stores;
    };
}
