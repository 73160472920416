import { UnknownAction } from "@reduxjs/toolkit";
import localForage from "localforage";
import { PersistConfig, persistCombineReducers } from "redux-persist";
// Common Reducers
import {
    coreReducers,
    resetFinancingData,
    sanitizeCheckoutData,
    sanitizeCommonData,
} from "tsi-common-react/src/apps/reducers";

import { IReduxState } from "./reducers.interfaces";

const storage = localForage.createInstance({
    name: "sealy-redux",
});
const persistConfig: PersistConfig<IReduxState> = {
    key: "redux-global",
    storage: storage,
    transforms: [sanitizeCommonData, sanitizeCheckoutData, resetFinancingData],
    whitelist: ["common", "checkout", "financing", "retail"],
};
export const reducers = persistCombineReducers<IReduxState, UnknownAction>(
    persistConfig,
    {
        ...coreReducers,
    },
);
