import Flickity from "flickity-imagesloaded";
import React from "react";
import { addProductToBasket } from "tsi-common-react/src/apps/configurator/actions";
import { IConcreteBundle } from "tsi-common-react/src/models/catalogue.interfaces";
import { IProductAPIURL } from "tsi-common-react/src/models/nominals";
import { ProductUpsellModalBlockSlide } from "tsi-common-react/src/models/streamfield-blocks";

import UpsellModalSlide from "./UpsellModalSlide";

interface IProps {
    slides: ProductUpsellModalBlockSlide[];
    bundles: IConcreteBundle[];
    onProceed: () => void;
}

interface IState {
    buyIsDisabled: boolean;
}

/**
 * A collection of UpsellModalSlide components
 */
export class UpsellModalSlideCollection extends React.Component<
    IProps,
    IState
> {
    state: IState = {
        buyIsDisabled: false,
    };

    private flickity: Flickity | null = null;
    private slidesElem: HTMLDivElement | null = null;

    private readonly onAddToBasket = async (productURL: IProductAPIURL) => {
        this.setState({ buyIsDisabled: true });
        try {
            await addProductToBasket(productURL);
        } catch (e) {
            this.setState({ buyIsDisabled: false });
            return;
        }
        this.props.onProceed();
    };

    componentDidMount() {
        this.initFlickity();
    }

    componentDidUpdate() {
        this.initFlickity();
    }

    private initFlickity() {
        if (!this.slidesElem) {
            return;
        }
        if (this.flickity) {
            this.flickity.destroy();
        }
        const initialSlide = this.props.slides.findIndex((s) => {
            return s.initial_mobile_slide;
        });
        if (this.slidesElem.childNodes.length > 1) {
            this.flickity = new Flickity(this.slidesElem, {
                cellAlign: "center",
                wrapAround: false,
                imagesLoaded: true,
                prevNextButtons: false,
                initialIndex: initialSlide,
                watchCSS: true,
            });
        }
    }

    private isAvailable(bundle: IConcreteBundle) {
        const product = bundle.suggested_products[0];
        return product.availability.is_available_to_buy;
    }

    render() {
        if (!this.props.slides) {
            return null;
        }
        const slides = this.props.slides.map((slide, i) => {
            const isBundle = slide.slide_bundle?.id !== 0;
            let bundle: IConcreteBundle | null = null;
            if (isBundle) {
                bundle = this.props.bundles.filter((b) => {
                    return b.bundle_group.id === slide.slide_bundle?.id;
                })[0];
            }
            if (!bundle) {
                return null;
            }
            // Skip rendering if product is not available
            if (!this.isAvailable(bundle)) {
                return null;
            }
            return (
                <UpsellModalSlide
                    key={i}
                    isBundle={isBundle}
                    bundle={bundle}
                    imagePath={slide.image.url}
                    imageAlt={slide.image.title}
                    ctaPath={slide.slide_link}
                    ctaCopy={slide.slide_cta}
                    buyIsDisabled={this.state.buyIsDisabled}
                    onAddToBasket={this.onAddToBasket}
                />
            );
        });
        return (
            <div
                className="upsell-modal__slides"
                ref={(ref) => {
                    this.slidesElem = ref;
                }}
            >
                {slides}
            </div>
        );
    }
}

export default UpsellModalSlideCollection;
