import React from "react";

import { IMenuFooter } from "../menu.interface";

interface IProps {
    navFooterEl: IMenuFooter;
}

const NavFooterComp = (props: IProps) => {
    return (
        <div className="header__dropdown-btns">
            <div
                dangerouslySetInnerHTML={{
                    __html: props.navFooterEl.value.html,
                }}
            ></div>
        </div>
    );
};

export default NavFooterComp;
