import memoize from "memoize-one";
import React from "react";
import { connect } from "react-redux";

import { IProduct } from "../../../models/catalogue.interfaces";
import {
    IImageURL,
    IProductID,
    ISafeHTML,
    IWebPageURL,
    ProductIDs,
} from "../../../models/nominals";
import { IAPIPrice, IPrice } from "../../../models/prices.interfaces";
import { IProductShippingMethod } from "../../../models/shipping.interfaces";
import { check } from "../../../models/utils";
import {
    trackListrackProductBrowse,
    trackVariantSelectedEvent,
} from "../../../utils/analytics";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { PLCConfigurator as PLCConfiguratorComponent } from "../components/PLCConfigurator";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import {
    getSelectedUpgradeIDFromURL,
    pushPDPVariantURLChange,
    startHistoryListener,
} from "../history";
import { Loaders } from "../loaders";
import { PLCOptionPanels, PLCProductCategorySelectors } from "../models";
import {
    IPLCOptionPanel,
    IPLCProductCategorySelector,
    IUpsellModalComponentClass,
} from "../models.interfaces";
import {
    addonVariantPricesSelector,
    baseVariantSelector,
    rootProductSelector,
    upgradedVariantPriceSelector,
    upgradedVariantSelector,
} from "../selectors";

interface IOwnProps {
    preheader?: string;
    title: string;
    subhead?: string;
    description?: string;
    footer: ISafeHTML;
    categorySelectors: string;
    products: string;
    optionPanels: string;
    starRatingURL: IWebPageURL;
    basketLink: IWebPageURL;
    configureGiftsLink: IWebPageURL;
    showShippingLead?: boolean;
    phoneNumber?: string;
    getDeliveryCopy?: (
        rootProduct: IProduct,
        isPreorder: boolean,
    ) => string | null;
    getDeliveryIcon?: () => IImageURL | null;
    // Root Product Variant ID which should be initially selected
    initialVariantID: IProductID | null;
    // Behavior options
    enableHistoryInteraction: boolean;
    // Optional: Up Sell Modal functionality
    showUpsellModal?: (product: IProduct | null) => boolean;
    getUpsellModalComponentClass?: (
        product: IProduct,
    ) => IUpsellModalComponentClass | null;
}

interface IReduxProps {
    rootProduct: IProduct | null;
    baseVariant: IProduct | null;
    selectedUpgradeID: IProductID | null;
    upgradedVariant: IProduct | null;
    price: IAPIPrice | null;
    selectedAddonPrice: IPrice | null;
    shippingMethod: IProductShippingMethod | null;
}

interface IDispatchProps {
    onDidMount: (opts: { enableHistoryInteraction: boolean }) => void;
    loaders: Loaders;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class PLCConfiguratorContainer extends React.PureComponent<IProps, IState> {
    private readonly parseCategorySelectors = memoize(
        (categorySelectorsJSON: string): IPLCProductCategorySelector[] => {
            const selectors = check(
                PLCProductCategorySelectors.decode(
                    JSON.parse(categorySelectorsJSON),
                ),
            );
            return selectors;
        },
    );

    private readonly parseProducts = memoize((productsJSON: string) => {
        const products = check(ProductIDs.decode(JSON.parse(productsJSON)));
        return products;
    });

    private readonly parseOptionPanels = memoize(
        (optionPanelsJSON: string): IPLCOptionPanel[] => {
            const panels = check(
                PLCOptionPanels.decode(JSON.parse(optionPanelsJSON)),
            );
            return panels;
        },
    );

    componentDidMount() {
        this.props.onDidMount({
            enableHistoryInteraction: !!this.props.enableHistoryInteraction,
        });
        // Load main product from API
        const categorySelectors = this.parseCategorySelectors(
            this.props.categorySelectors,
        );
        const optionPanels = this.parseOptionPanels(this.props.optionPanels);
        const rootProductIDs = this.parseProducts(this.props.products);
        if (rootProductIDs.length <= 0) {
            throw new Error("rootProductIDs array must not be empty");
        }
        this.props.loaders.loadProducts(
            rootProductIDs,
            this.props.initialVariantID,
            categorySelectors,
            optionPanels,
        );
    }

    componentDidUpdate(prevProps: IProps) {
        // Did the base selection change?
        const prevBaseVariantID =
            prevProps.baseVariant && prevProps.baseVariant.id
                ? prevProps.baseVariant.id
                : null;
        const nextBaseVariantID =
            this.props.baseVariant && this.props.baseVariant.id
                ? this.props.baseVariant.id
                : null;
        const baseVariantChanged = prevBaseVariantID !== nextBaseVariantID;

        // Did the upgraded variant change?
        const prevUpgradedVariantID =
            prevProps.upgradedVariant && prevProps.upgradedVariant.id
                ? prevProps.upgradedVariant.id
                : null;
        const nextUpgradedVariantID =
            this.props.upgradedVariant && this.props.upgradedVariant.id
                ? this.props.upgradedVariant.id
                : null;
        const upgradedVariantChanged =
            prevUpgradedVariantID !== nextUpgradedVariantID;

        // Load bundle data for the new variant
        if (baseVariantChanged && nextBaseVariantID) {
            const selectedUpgradeID = getSelectedUpgradeIDFromURL();
            this.props.loaders.loadConcreteBundles(
                nextBaseVariantID,
                selectedUpgradeID,
            );
            // Push if variantID is changed
            trackVariantSelectedEvent(nextBaseVariantID);
        }

        // Update the page URL
        if (
            this.props.enableHistoryInteraction &&
            (baseVariantChanged || upgradedVariantChanged)
        ) {
            const categorySelectors = this.parseCategorySelectors(
                this.props.categorySelectors,
            );
            pushPDPVariantURLChange(
                this.props.rootProduct,
                this.props.baseVariant,
                this.props.selectedUpgradeID,
                categorySelectors,
            );
            // Refire Listrak AddProductBrowse activity only when the selected variant is changed.
            if (
                prevProps.baseVariant &&
                this.props.baseVariant &&
                this.props.baseVariant.skus.length > 0
            ) {
                trackListrackProductBrowse(this.props.baseVariant.skus[0]);
            }
        }
    }

    render() {
        const categorySelectors = this.parseCategorySelectors(
            this.props.categorySelectors,
        );
        const optionPanels = this.parseOptionPanels(this.props.optionPanels);
        return (
            <PLCConfiguratorComponent
                preheader={this.props.preheader}
                subhead={this.props.subhead}
                description={this.props.description}
                title={this.props.title}
                footer={this.props.footer}
                categorySelectors={categorySelectors}
                optionPanels={optionPanels}
                upgradedVariant={this.props.upgradedVariant}
                price={this.props.price}
                selectedAddonPrice={this.props.selectedAddonPrice}
                starRatingURL={this.props.starRatingURL}
                basketLink={this.props.basketLink}
                configureGiftsLink={this.props.configureGiftsLink}
                showUpsellModal={this.props.showUpsellModal}
                getUpsellModalComponentClass={
                    this.props.getUpsellModalComponentClass
                }
                showShippingLead={this.props.showShippingLead}
                shippingDescription={
                    this.props.shippingMethod
                        ? this.props.shippingMethod.description
                        : null
                }
                rootProduct={this.props.rootProduct}
                phoneNumber={this.props.phoneNumber}
                getDeliveryCopy={this.props.getDeliveryCopy}
                getDeliveryIcon={this.props.getDeliveryIcon}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProduct: rootProductSelector(state),
        baseVariant: baseVariantSelector(state),
        selectedUpgradeID: state.ui.selectedUpgrade,
        upgradedVariant: upgradedVariantSelector(state),
        price: upgradedVariantPriceSelector(state),
        selectedAddonPrice: addonVariantPricesSelector(state),
        shippingMethod: state.shippingMethod,

        // Direct Props
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        onDidMount: ({ enableHistoryInteraction }) => {
            if (enableHistoryInteraction) {
                startHistoryListener(dispatch);
            }
        },
        // dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const PLCConfigurator = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PLCConfiguratorContainer);
