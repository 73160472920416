import React from "react";

import { IDropdownMenuItem, IMenuCategoryLink } from "../menu.interface";
import NavFooter from "./NavFooter";

interface IProps {
    linksArray: IDropdownMenuItem[];
    footerlinks: IMenuCategoryLink[];
    linkID: number;
    closeModal: (event: React.MouseEvent<Element>) => void;
}

const DropdownLinkList = (props: IProps) => {
    const pageLinkWithImageType = "page_link_with_image";
    const imageUrl = "imageUrl";
    const pageUrl = "pageUrl";

    let menuLinksEachCol: JSX.Element[] = [];

    const getString = (obj: IDropdownMenuItem, opt: string) => {
        let str = "";
        switch (obj.type) {
            case "external_link":
                str = obj.value.link;
                break;
            case "relative_url":
                str = obj.value.link;
                break;
            case pageLinkWithImageType:
                if (opt === imageUrl) {
                    str = obj.value.image.url;
                } else if (opt === pageUrl) {
                    str = obj.value.page.url;
                } else {
                    str = obj.value.tagline;
                }
                break;

            default:
                break;
        }
        return str;
    };

    const buildColumn = (obj: IDropdownMenuItem, id: number) => {
        if (obj.type === "sub_title") {
            let col: JSX.Element | null = null;
            col = (
                <span key={id}>
                    <h4>
                        {obj.value.subtitle}
                        <br />
                        <span className="legal">{obj.value.small_copy}</span>
                    </h4>
                    {menuLinksEachCol}
                </span>
            );
            menuLinksEachCol = [];

            return col;
        } else {
            return null;
        }
    };

    const dropdownLinks = props.linksArray.map((obj, id) => {
        const link = getString(obj, pageUrl);
        let image: JSX.Element | null = null;
        let tagline: JSX.Element | null = null;
        let menuLink: JSX.Element | null = null;
        let column: JSX.Element | null = null;

        if (obj.type === pageLinkWithImageType) {
            image = <img src={getString(obj, imageUrl)} alt="" />;
            tagline = <p>{getString(obj, "tagline")}</p>;
        }

        if (obj.value.override_title) {
            menuLink = (
                <button
                    key={id}
                    className="header__dropdown-item"
                    onClick={() => {
                        window.parent.location.href = link;
                    }}
                >
                    {image}
                    <p>{obj.value.override_title}</p>
                    {tagline}
                </button>
            );
        } else {
            menuLink = <span key={id}></span>;
        }

        menuLinksEachCol.push(menuLink);
        column = buildColumn(obj, id);

        if (obj.type !== "sub_title") {
            return;
        }

        return (
            <div key={id} className="header__dropdown-col">
                {column}
            </div>
        );
    });

    return (
        <div key={props.linkID} className="header__dropdown-nav">
            <div className="header__dropdown-columns">{dropdownLinks}</div>
            <NavFooter flinks={props.footerlinks} />
        </div>
    );
};

export default DropdownLinkList;
