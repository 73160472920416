import classNames from "classnames";
import React from "react";
import Modal from "react-modal";
import {
    OnResizeHandler,
    WindowResizeSensor,
} from "tsi-common-react/src/common/WindowResizeSensor";
import { BreakPoint } from "tsi-common-react/src/models/screen.interfaces";
import {
    getViewportBreakpoint,
    isMobile,
} from "tsi-common-react/src/utils/detectMobile";

import DropDownCloseButton from "../components/DropdownCloseButton";
import DropdownLinkList from "../components/DropdownLinkList";
import { NavAdvert } from "../components/NavAdvert";
import {
    IMenuCategoryLink,
    IMenuItem,
    IMenuMobileTop,
    IMenuPageLink,
} from "../menu.interface";

interface IProps {
    isAdmin: boolean;
    menuItems: IMenuItem[];
}

interface IState {
    modalIsOpen: boolean;
    isMobileNav: boolean;
    initDropdownAni: boolean;
}

export class MainMenu extends React.Component<IProps, IState> {
    public state: IState = {
        modalIsOpen: false,
        isMobileNav: false,
        initDropdownAni: false,
    };

    private readonly onResize: OnResizeHandler = (event) => {
        // If only the height changed, don't do anything
        if (event.type === "height") {
            return;
        }
        // Test for mobile view on android
        if (this.isSmallScreenWidth() && isMobile.Android()) {
            return;
        }
        if (this.state.modalIsOpen) {
            // If the modal is open and the window is resized, close the modal
            this.closeModal();
        }
    };

    private isSmallScreenWidth() {
        return getViewportBreakpoint() < BreakPoint.SMALL;
    }

    private renderLink(
        mobile: boolean,
        url: string,
        overrideTitle: string,
        linkID: number,
    ) {
        const linkClassName = classNames({
            "header__nav-item": true,
            "header__nav-item--mobile": mobile,
        });

        return (
            <a key={linkID} className={linkClassName} href={url}>
                {overrideTitle}{" "}
            </a>
        );
    }

    private openModal(event: React.MouseEvent<HTMLElement>) {
        if (event) {
            event.preventDefault();
        }

        if (event.currentTarget.id === "mobile-main-nav") {
            this.setState({
                isMobileNav: true,
            });

            setTimeout(() => {
                this.setState({ initDropdownAni: true });
            }, 10);
        }

        this.setState({
            modalIsOpen: !this.state.modalIsOpen,
        });
    }

    private readonly closeModal = (
        event?: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        if (this.state.isMobileNav) {
            this.setState({
                initDropdownAni: false,
            });
        }

        setTimeout(
            () => {
                this.setState({
                    modalIsOpen: false,
                    isMobileNav: false,
                });
            },
            this.state.isMobileNav ? 250 : 0,
        );
    };

    private renderCategoryLink(linkObject: IMenuCategoryLink, linkID: number) {
        return (
            <button
                key={linkID}
                onClick={(e) => {
                    this.openModal(e);
                }}
                className="header__nav-item"
            >
                {linkObject.value.title}
            </button>
        );
    }

    private renderMenuLinks(mobile: boolean, isSubNav: boolean) {
        let index = 0;
        let menuClassName = !isSubNav ? "header__primary-nav" : "";
        menuClassName = this.state.modalIsOpen
            ? `${menuClassName} is-expanded`
            : menuClassName;

        const buildPageLinks = (menuItem: IMenuPageLink) => {
            return this.renderLink(
                false,
                menuItem.value.page.url,
                menuItem.value.override_title,
                index++,
            );
        };

        const buildCategoryLinks = (menuItem: IMenuCategoryLink) => {
            const links: JSX.Element[] = [];

            // Build out Dropdown nav links (if they exist)
            const dropdownLinks = menuItem.value.sub_nav.filter((val) => {
                return (
                    val.type === "page_link_with_image" ||
                    val.type === "external_link" ||
                    val.type === "sub_title" ||
                    val.type === "relative_url"
                );
            });

            if (!isSubNav) {
                const link = this.renderCategoryLink(menuItem, index++);
                links.push(link);
            }

            if (dropdownLinks.length <= 0) {
                return links;
            }

            if (isSubNav) {
                links.push(
                    <DropdownLinkList
                        linksArray={dropdownLinks}
                        key={index++}
                        linkID={index++}
                        footerlinks={
                            this.props.menuItems as IMenuCategoryLink[]
                        }
                        closeModal={this.closeModal}
                    />,
                );
                return links;
            }

            return links;
        };

        const buildMobileMenu = (menuItem: IMenuMobileTop) => {
            const page = menuItem.value.page;
            const url = page ? page.url : menuItem.value.external_link;

            return this.renderLink(true, url, menuItem.value.title, index++);
        };

        const menuLinks = this.props.menuItems.reduce(
            (memo, menuItem: IMenuItem) => {
                switch (menuItem.type) {
                    case "external_link":
                    case "mobile_top":
                        if (mobile) {
                            memo = memo.concat(
                                buildMobileMenu(menuItem as IMenuMobileTop),
                            );
                        }
                        break;

                    case "page_link":
                        if (!isSubNav && !mobile) {
                            memo = memo.concat(buildPageLinks(menuItem));
                        }
                        break;

                    case "nav_category":
                        if (!mobile) {
                            memo = memo.concat(buildCategoryLinks(menuItem));
                        }
                        break;

                    default:
                        console.error(
                            `Unknown menu item type: ${menuItem.type}`,
                        );
                }
                return memo;
            },
            [] as JSX.Element[],
        );

        return <nav className={menuClassName}>{menuLinks}</nav>;
    }

    render() {
        const isMobileNav = this.state.isMobileNav;
        const contentPosition: React.CSSProperties["position"] = isMobileNav
            ? "absolute"
            : "fixed";
        const contentTop: React.CSSProperties["top"] = this.props.isAdmin
            ? "208px"
            : "130px";
        const contentHeight: React.CSSProperties["height"] = "auto";
        const modalStyle: Modal.Styles = {
            overlay: {
                position: isMobileNav ? "absolute" : "fixed",
                top: !isMobileNav ? contentTop : 0,
                backgroundColor: !isMobileNav
                    ? "rgba(0, 0, 0, 0.2)"
                    : "rgba(0, 0, 0, 0)",
                zIndex: 100,
            },
            content: {
                position: contentPosition,
                top: !isMobileNav ? contentTop : 0,
                left: "0",
                right: "0",
                width: "100%",
                height: !isMobileNav ? contentHeight : "100%",
                overflow: "hidden",
                border: "none",
                padding: "0",
                borderTop: "1px solid #d8dcdd",
                backgroundColor: !isMobileNav
                    ? "rgba(255, 255, 255, 1)"
                    : "rgba(255, 255, 255, 0)",
                boxShadow: "0 10px 10px -5px rgba(0,0,0,.3)",
            },
        };

        const navAdvert = () => {
            if (isMobileNav) {
                return;
            }
            return (
                <NavAdvert
                    menuItems={this.props.menuItems as IMenuCategoryLink[]}
                />
            );
        };

        const dropdownMenuClassName = classNames({
            "header__dropdown-menu": true,
            "header__dropdown-menu--active": this.state.initDropdownAni,
        });

        return (
            <div>
                <WindowResizeSensor onResize={this.onResize} />
                {this.renderMenuLinks(false, false)}
                <Modal
                    style={modalStyle}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                >
                    <DropDownCloseButton
                        isMobileNav={isMobileNav}
                        closeModal={this.closeModal}
                    />

                    <div className={dropdownMenuClassName}>
                        {this.renderMenuLinks(isMobileNav, true)}
                        {navAdvert()}
                    </div>
                </Modal>
            </div>
        );
    }
}
