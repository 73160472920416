import className from "classnames";
import React from "react";
import { connect } from "react-redux";

import { STR_NO, STR_YES } from "../../../constants";
import {
    IOptionSingleValue,
    IOptionValues,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { isoImageURL } from "../../../models/nominals";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import {
    IPLCBooleanOptionSelectorOption,
    IPLCOptionPanel,
    IPLCProductCategorySelector,
} from "../models.interfaces";
import { getMostSimilarProduct } from "../product-similarity";
import { ISelectedCategories } from "../reducers.interfaces";
import { selectedCategoriesSelector } from "../selectors";
import { getOptionValueByIdx } from "../utils";
import { PLCInfoPanel } from "./PLCInfoPanel";

// Load styles
import "./PLCBooleanOptionSelector.scss";

interface IOwnProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    groupID: string;
    selector: IPLCBooleanOptionSelectorOption;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    value: IOptionSingleValue | undefined;
}

interface IDispatchProps {
    onChange: Dispatchers["setOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

const BEM_NAME = "plc-boolean-option-selector";

class PLCBooleanOptionSelectorComponent extends React.PureComponent<
    IProps,
    IState
> {
    /**
     * Check if selecting this option will result in a perfect product match or not. If
     * not, the option should be visually disabled (but still actually selectable).
     */
    private isDisabled(option: IOptionSingleValue): boolean {
        const futureOptionValues = {
            ...this.props.optionValues,
            [this.props.selector.attribute]: option,
        };
        const result = getMostSimilarProduct({
            categorySelectors: this.props.categorySelectors,
            optionPanels: this.props.optionPanels,
            rootProducts: this.props.rootProducts,
            selectedCategories: this.props.selectedCategories,
            optionValues: futureOptionValues,
            previouslySelectedCategories: this.props.selectedCategories,
            previouslySelectedOptionValues: this.props.optionValues,
        });
        return !result || result.distance > 0;
    }

    render() {
        const optionID = `${this.props.groupID}-${this.props.selector.name}`
            .replace(/[^a-zA-Z0-9-]/gi, "")
            .toLowerCase();
        const descriptionID = `${optionID}-description`;
        const isChecked = this.props.value === STR_YES;
        const isDisabled = this.isDisabled(STR_YES);
        const listElementClass = className({
            [`${BEM_NAME}__list-element`]: true,
            [`${BEM_NAME}__list-element--faux-disabled`]: isDisabled,
        });
        const buttonClass = className({
            [`${BEM_NAME}`]: true,
            [`${BEM_NAME}--selected`]: isChecked,
            [`${BEM_NAME}--faux-disabled`]: isDisabled,
        });
        const labelClass = className({
            [`${BEM_NAME}__label`]: true,
            [`${BEM_NAME}__label--faux-disabled`]: isDisabled,
        });
        return (
            <li className={listElementClass}>
                <div
                    className={buttonClass}
                    onClick={() => {
                        const value = !isChecked ? STR_YES : STR_NO;
                        this.props.onChange(
                            "",
                            this.props.selector.attribute,
                            0,
                            1,
                            value,
                        );
                    }}
                >
                    <div className={`${BEM_NAME}__icon`}>
                        {this.props.selector.image && (
                            <img
                                src={isoImageURL.unwrap(
                                    this.props.selector.image.url,
                                )}
                                alt={this.props.selector.name}
                            />
                        )}
                    </div>
                    <div className={`${BEM_NAME}__copy`}>
                        <div>
                            <label
                                htmlFor={optionID}
                                className={labelClass}
                                aria-selected={false}
                                aria-describedby={descriptionID}
                            >
                                {this.props.selector.name}
                            </label>
                        </div>
                        <p
                            id={descriptionID}
                            className={`${BEM_NAME}__description`}
                        >
                            {this.props.selector.description}
                        </p>
                    </div>
                    <div className={`${BEM_NAME}__input-container`}>
                        <input
                            type="checkbox"
                            className={`${BEM_NAME}__input`}
                            id={optionID}
                            name={this.props.selector.attribute}
                            onChange={(e) => {
                                const value = e.currentTarget.checked
                                    ? STR_YES
                                    : STR_NO;
                                this.props.onChange(
                                    "",
                                    this.props.selector.attribute,
                                    0,
                                    1,
                                    value,
                                );
                            }}
                            checked={this.props.value === STR_YES}
                        />
                    </div>
                </div>
                <PLCInfoPanel
                    buttonText={"Learn More"}
                    content={this.props.selector.help_content}
                />
            </li>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProducts: state.entities.rootProducts,
        selectedCategories: selectedCategoriesSelector(state),
        optionValues: state.ui.optionValues,
        value: getOptionValueByIdx(
            state.ui.optionValues[ownProps.selector.attribute],
            0,
        ),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onChange: (...args) => {
            dispatchers.setOptionValue(...args);
        },
    };
};

export const PLCBooleanOptionSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PLCBooleanOptionSelectorComponent);
