import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconCaretDownAccount from "../../../../../img/icons/caret-down-account.svg";
import iconCircleClose from "../../../../../img/icons/circle-close.svg";
import iconMobileAccount from "../../../../../img/icons/mobile-account.svg";
import { useIsSSR } from "../../../../utils/ssr";
import { useAppSelector } from "../../../reducers";
import { AccountMenuContent } from "../AccountMenuContent";

import styles from "./index.module.scss";

export const AccountMenu = () => {
    const isSSR = useIsSSR();
    const user = useAppSelector((state) => state.common.user);
    let accountText: string = t`Support`;
    if (user && user.first_name && user.has_password) {
        accountText = t`Hi, ${user.first_name}`;
    }
    return (
        <div>
            <Dialog.Root>
                <Dialog.Trigger className={styles.accountMenuLink}>
                    <span className={styles.accountMenuLinkText}>
                        {accountText}
                        &nbsp;
                        <SVG
                            src={iconCaretDownAccount}
                            title={t`View Account Icon`}
                            aria-hidden="true"
                        />
                    </span>
                    <span className={styles.accountMenuLinkIcon}>
                        <SVG
                            src={iconMobileAccount}
                            title={t`View Account Icon`}
                            aria-hidden="true"
                        />
                    </span>
                </Dialog.Trigger>
                <div className={styles.wrapper}>
                    <Dialog.Content
                        className={styles.container}
                        aria-describedby={undefined}
                    >
                        <Dialog.Close className={styles.closeButton}>
                            <SVG
                                src={iconCircleClose}
                                title={t`Close`}
                                aria-hidden="true"
                            />
                        </Dialog.Close>
                        {!isSSR && <AccountMenuContent user={user} />}
                    </Dialog.Content>
                </div>
            </Dialog.Root>
        </div>
    );
};
