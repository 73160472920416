import React, { useMemo } from "react";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { Link } from "tsi-common-react/src/common/Link";
import { getDefaultFinancingPlan } from "tsi-common-react/src/utils/financing";
import { formatFinancingPlan } from "tsi-common-react/src/utils/format";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import sealyCreditCardHighRes from "../../img/finance/sealy-credit-card-high-res.png";

interface IProps {
    applicationSource?: string;
}

export const FinancingBannerBlock = (props: IProps) => {
    const plan = useMemo(
        () => formatFinancingPlan(getDefaultFinancingPlan()),
        [],
    );
    return (
        <section className="financing-banner-block">
            <div>
                <img
                    alt={t`Tempur-Pedic Credit Card`}
                    src={sealyCreditCardHighRes}
                />
                <div>
                    <h3>
                        {t`${plan.apr} APR for ${plan.length} Months`}
                        <sup>
                            <Link href={urls.pageURL("finance-link")}>
                                {plan.superscript}
                            </Link>
                        </sup>
                    </h3>
                    <div>{t`with minimum purchase of ${plan.threshold}`}</div>
                    <ul>
                        <li>
                            No annual fee <sup>1</sup>
                        </li>
                        <li>Pre-qualify without impacting your credit score</li>
                        <li>Apply now or during checkout</li>
                    </ul>
                </div>
                <div>
                    <FinancingModalTrigger
                        modalType="prequal-app"
                        className="button button--primary-inverted"
                        applicationSource={props.applicationSource}
                    >
                        {t`Check if You Pre-Qualify`}
                    </FinancingModalTrigger>
                    <FinancingModalTrigger
                        modalType="full-app"
                        className="button button--white-outline"
                        applicationSource={props.applicationSource}
                    >
                        {t`Apply Now`}
                    </FinancingModalTrigger>
                </div>
            </div>
        </section>
    );
};
