import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { t } from "ttag";

import iconSealySizeCaKing from "../../../../img/icons/sealy-size-ca-king.svg";
import iconSealySizeFull from "../../../../img/icons/sealy-size-full.svg";
import iconSealySizeKing from "../../../../img/icons/sealy-size-king.svg";
import iconSealySizeQueen from "../../../../img/icons/sealy-size-queen.svg";
import iconSealySizeSplitKing from "../../../../img/icons/sealy-size-split-king.svg";
import iconSealySizeSplitQueen from "../../../../img/icons/sealy-size-split-queen.svg";
import iconSealySizeTwinLong from "../../../../img/icons/sealy-size-twin-long.svg";
import iconSealySizeTwin from "../../../../img/icons/sealy-size-twin.svg";
import {
    IOptionSingleValue,
    IOptionValues,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import {
    IPLCMattressSizeOptionSelector,
    IPLCOptionPanel,
    IPLCProductCategorySelector,
} from "../models.interfaces";
import { getMostSimilarProduct } from "../product-similarity";
import { ISelectedCategories } from "../reducers.interfaces";
import { selectedCategoriesSelector } from "../selectors";
import { getOptionValueByIdx } from "../utils";
import { PLCInfoPanel } from "./PLCInfoPanel";

// Load styles
import "./PLCMattressSizeOptionSelector.scss";

interface IOwnProps {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    selector: IPLCMattressSizeOptionSelector;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    value: IOptionSingleValue | undefined;
}

interface IDispatchProps {
    onChange: Dispatchers["setOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

const BEM_NAME = "plc-mattress-size-option-selector";

const SIZES = [
    {
        label: t`Twin`,
        value: "Twin",
        iconPath: iconSealySizeTwin,
    },
    {
        label: t`Twin Long`,
        value: "Twin Long",
        iconPath: iconSealySizeTwinLong,
    },
    {
        label: t`Full`,
        value: "Full",
        iconPath: iconSealySizeFull,
    },
    {
        label: t`Queen`,
        value: "Queen",
        iconPath: iconSealySizeQueen,
    },
    {
        label: t`Split Queen`,
        value: "Split Queen",
        iconPath: iconSealySizeSplitQueen,
    },
    {
        label: t`King`,
        value: "King",
        iconPath: iconSealySizeKing,
    },
    {
        label: t`Split King`,
        value: "Split King",
        iconPath: iconSealySizeSplitKing,
    },
    {
        label: t`CA King`,
        value: "CA King",
        iconPath: iconSealySizeCaKing,
    },
    {
        label: t`Split CA King`,
        value: "Split CA King",
        iconPath: iconSealySizeSplitKing,
    },
];

const SIZE_ATTR = "option_size";

class PLCMattressSizeOptionSelectorComponent extends React.PureComponent<
    IProps,
    IState
> {
    /**
     * Check if selecting this option will result in a perfect product match or not. If
     * not, the option should be visually disabled (but still actually selectable).
     */
    private isDisabled(option: IOptionSingleValue): boolean {
        const futureOptionValues = {
            ...this.props.optionValues,
            [SIZE_ATTR]: option,
        };
        const result = getMostSimilarProduct({
            categorySelectors: this.props.categorySelectors,
            optionPanels: this.props.optionPanels,
            rootProducts: this.props.rootProducts,
            selectedCategories: this.props.selectedCategories,
            optionValues: futureOptionValues,
            previouslySelectedCategories: this.props.selectedCategories,
            previouslySelectedOptionValues: this.props.optionValues,
        });
        return !result || result.distance > 0;
    }

    render() {
        const groupID = this.props.selector.id;

        return (
            <fieldset className={BEM_NAME}>
                <legend>
                    <span>{t`Mattress Size`}</span>
                </legend>
                <ul>
                    {SIZES.map((opt) => {
                        const optionID = `${groupID}-${opt.value}`
                            .replace(/[^a-zA-Z0-9-]/gi, "")
                            .toLowerCase();
                        const isChecked = this.props.value === opt.value;
                        const isDisabled = this.isDisabled(opt.value);
                        const listElementClass = classNames({
                            [`${BEM_NAME}__option`]: true,
                            [`${BEM_NAME}__option--selected`]: isChecked,
                            [`${BEM_NAME}__option--faux-disabled`]: isDisabled,
                        });
                        const inputClass = classNames({
                            [`${BEM_NAME}__input`]: true,
                            [`${BEM_NAME}__input--faux-disabled`]: isDisabled,
                        });
                        return (
                            <li
                                key={opt.value}
                                className={listElementClass}
                                onClick={() => {
                                    if (!isChecked) {
                                        this.props.onChange(
                                            "",
                                            SIZE_ATTR,
                                            0,
                                            1,
                                            opt.label,
                                        );
                                    }
                                }}
                            >
                                <div>
                                    <SVG src={opt.iconPath} />
                                </div>
                                <input
                                    type="radio"
                                    className={inputClass}
                                    id={optionID}
                                    name={groupID}
                                    onChange={(e) => {
                                        if (e.currentTarget.checked) {
                                            this.props.onChange(
                                                "",
                                                SIZE_ATTR,
                                                0,
                                                1,
                                                opt.value,
                                            );
                                        }
                                    }}
                                    checked={isChecked}
                                    value={opt.value}
                                />
                                <label
                                    htmlFor={optionID}
                                    className={`${BEM_NAME}__label`}
                                    aria-selected={false}
                                >
                                    {opt.label}
                                </label>
                            </li>
                        );
                    })}
                </ul>
                <PLCInfoPanel
                    buttonText={t`Which size is right for me?`}
                    content={this.props.selector.value.help_content}
                />
            </fieldset>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProducts: state.entities.rootProducts,
        selectedCategories: selectedCategoriesSelector(state),
        optionValues: state.ui.optionValues,
        value: getOptionValueByIdx(state.ui.optionValues[SIZE_ATTR], 0),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onChange: (...args) => {
            dispatchers.setOptionValue(...args);
        },
    };
};

export const PLCMattressSizeOptionSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PLCMattressSizeOptionSelectorComponent);
