import * as t from "io-ts";
import { ImageURL, ProductID } from "tsi-common-react/src/models/nominals";
import {
    ProductUpsellModalBlock,
    VariantDescriptionBlock,
    VariantHeroBlock,
} from "tsi-common-react/src/models/streamfield-blocks";
import { nullable } from "tsi-common-react/src/models/utils";

// TODO: need to check if old pages works correctly
export const ProductPage = t.intersection([
    t.interface({
        title: t.string,
        description: nullable(t.string),
        products: t.array(ProductID),
        shipping_description: nullable(t.string),
        shipping_icon_url: nullable(ImageURL),
        show_shipping_lead: t.boolean,
        show_financing_modal: t.boolean,
        hero_image_url: nullable(ImageURL),
        mobile_hero_image_url: nullable(ImageURL),
        hero_image_alt: nullable(t.string),
        bundle_modal_serialized: t.array(ProductUpsellModalBlock),
    }),
    t.partial({
        variant_hero_images_with_urls: t.array(VariantHeroBlock),
        variant_configurator_description_data: t.array(VariantDescriptionBlock),
        preheader: t.string,
        subhead: t.string,
        live_chat_header: t.string,
        learn_more_target: t.string,
    }),
]);
export interface IProductPage extends t.TypeOf<typeof ProductPage> {}
