import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { AccountMenu } from "tsi-common-react/src/apps/authentication/components/AccountMenu/index";
import { BasketLineVariant } from "tsi-common-react/src/apps/checkout/constants";
import { BasketMenuIcon } from "tsi-common-react/src/apps/checkout/containers/BasketMenuIcon";
import { RichNavigation } from "tsi-common-react/src/apps/rich-navigation/RichNavigation";
import Link from "tsi-common-react/src/common/Link";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { strToBool } from "tsi-common-react/src/utils/format";
import { useCurrentBreakpoint } from "tsi-common-react/src/utils/hooks";
import {
    getPageSetting,
    isPageInsideIFrame as isPageInsideIFrameCheck,
} from "tsi-common-react/src/utils/settings";
import { useIsSSR } from "tsi-common-react/src/utils/ssr";
import { urls } from "tsi-common-react/src/utils/urls";
import { buildMobileNavToggleClickHandler } from "tsi-common-react/src/widgets/rich-navigation";
import { t } from "ttag";

import { templates } from "@reactivated";

import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

import { FinancingModalTriggerMinicart } from "../src/Checkout/components/FinancingModalTriggerMinicart";
import { MainMenu } from "../src/Common/containers/MainMenu";
import { store } from "../src/store";

import styles from "./SiteHeaderTemplate.module.scss";

export const Template = (props: templates.SiteHeaderTemplate) => {
    const isSSR = useIsSSR();
    const enableBasketPrequalSDK = isSSR
        ? false
        : strToBool(getPageSetting("show-prequal-cart"));
    const basketURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("basket-summary");
    const checkoutURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("checkout-index");
    const retailURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("find-a-retailer");
    const isPageInsideIFrame = isSSR ? false : isPageInsideIFrameCheck();
    const isAdmin = isSSR
        ? false
        : !(document.querySelector(".csr-toolbar") === null);
    const viewport = useCurrentBreakpoint();

    useEffect(() => {
        // Including legacy EventListener code during the ongoing RichNavigation refactor work, -@zlebar
        Array.from(
            document.querySelectorAll<HTMLElement>(".mobile-toggle"),
        ).forEach((elem) => {
            elem.addEventListener(
                "click",
                buildMobileNavToggleClickHandler(elem),
            );
        });
    }, []);

    return (
        <>
            {viewport.aboveSmall && (
                <div className={styles.promoBanner}>
                    <div className={styles.wrapper}>
                        {props.promo_messaging_banner && (
                            <RichTextBlock
                                value={
                                    props.promo_messaging_banner.promo_message
                                        ?.html || ""
                                }
                            />
                        )}
                        <div>
                            <Link
                                className={styles.retailLink}
                                href={retailURL}
                                title="Find a Retailer"
                                target="_top"
                            >
                                <svg className={styles.retailIcon}>
                                    <title>{t`Find a Retailer Icon`}</title>
                                    <use xlinkHref="#icon-pin" />
                                </svg>
                                <span className="is-desktop">{t`Find a Retailer`}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            <header className={styles.root}>
                <nav className={styles.wrapper}>
                    <a className={styles.screenReaderSkip} href="#main-content">
                        {t`Skip to main content`}
                    </a>
                    <button className="mobile-toggle">
                        <span className="burger-icon"></span>
                    </button>
                    <a href="/" title="Sealy Home" target="_top">
                        {viewport.aboveMobile && (
                            <svg
                                className={styles.desktopLogo}
                                role="img"
                                aria-labelledby="desktop-only-logo"
                            >
                                <title id="desktop-only-logo">
                                    {t`Sealy Homepage`}
                                </title>
                                <use xlinkHref="#bowtie" />
                            </svg>
                        )}
                        {viewport.belowMobile && (
                            <svg
                                className={styles.mobileLogo}
                                role="img"
                                aria-labelledby="mobile-only-logo"
                            >
                                <title id="mobile-only-logo">
                                    {t`Sealy Homepage`}
                                </title>
                                <use xlinkHref="#wordmark" />
                            </svg>
                        )}
                    </a>
                    <div className={styles.nav}>
                        {!props.desktop_rich_navigation && (
                            <MainMenu
                                isAdmin={isAdmin}
                                menuItems={JSON.parse(props.nav_menu)}
                            />
                        )}
                        {props.desktop_rich_navigation && (
                            <RichNavigation
                                {...props.desktop_rich_navigation}
                            />
                        )}
                    </div>
                    <div className={styles.secondNav}>
                        {viewport.belowMobile && (
                            <Link
                                href={retailURL}
                                title="Find a Retailer"
                                target="_top"
                            >
                                <svg className={styles.mobileRetailIcon}>
                                    <title>{t`Find a Retailer Icon`}</title>
                                    <use xlinkHref="#icon-pin" />
                                </svg>
                            </Link>
                        )}
                        <Provider store={store}>
                            <AccountMenu />
                        </Provider>
                        <Provider store={store}>
                            <BasketMenuIcon
                                className={styles.basketLink}
                                basketURL={basketURL}
                                checkoutURL={checkoutURL}
                                isPageInsideIFrame={isPageInsideIFrame}
                                basketLineVariant={
                                    BasketLineVariant.MINIMAL_ENHANCED
                                }
                                buildPromoComponents={(closeModal) => {
                                    if (!enableBasketPrequalSDK) {
                                        return null;
                                    }
                                    return (
                                        <Provider store={store}>
                                            <FinancingModalTriggerMinicart
                                                closeModal={closeModal}
                                            />
                                        </Provider>
                                    );
                                }}
                                isSSR={isSSR}
                            />
                        </Provider>
                    </div>
                </nav>
            </header>
        </>
    );
};
