import React from "react";
import { connect } from "react-redux";

import { TStateMapper } from "../../../apps/reducers.interfaces";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "../../../models/location.interfaces";
import { IWebPageURL } from "../../../models/nominals";
import { preferredLocationSelector } from "../../common/selectors";
import { RetailCardsBlock } from "../components/RetailCards";
import { filteredStoreSelector } from "../selectors";

interface IOwnProps {
    retailLocatorLink: IWebPageURL;
}

interface IReduxProps {
    location: ILocation | null;
    stores: IRetailStoreWithDistance[];
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class RetailCardsContainer extends React.Component<IProps, IState> {
    render() {
        return (
            <RetailCardsBlock
                location={this.props.location}
                stores={this.props.stores}
                retailLocatorLink={this.props.retailLocatorLink}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        location: preferredLocationSelector(rootState),
        stores: filteredStoreSelector(rootState),
        ...ownProps,
    };
};

export const RetailCards = connect(mapStateToProps)(RetailCardsContainer);
