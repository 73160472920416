import React from "react";
import { PromoAd } from "tsi-common-react/src/blocks/components/PromoAd";

import { IDropdownMenuItem, IMenuCategoryLink } from "../menu.interface";

interface IProps {
    menuItems: IMenuCategoryLink[];
}

export const NavAdvert = (props: IProps) => {
    const promoAds: JSX.Element[] = [];
    let isDefaultAdAdded: boolean = false;
    props.menuItems.forEach((menuItem: IMenuCategoryLink) => {
        if (
            menuItem.type !== "nav_category" ||
            !menuItem.value.sub_nav.length
        ) {
            return;
        }

        menuItem.value.sub_nav.forEach((val: IDropdownMenuItem, itemIndex) => {
            if (val.type === "nav_advert") {
                if (val.value.promo_period) {
                    // Remove the default promoAd if it exists
                    if (isDefaultAdAdded) {
                        if (promoAds.length > 0) {
                            promoAds.pop();
                        }
                    }

                    promoAds.push(
                        <PromoAd key={itemIndex} promoArrayEl={val} />,
                    );
                } else if (
                    promoAds.length === 0 &&
                    (val.value.promo_period === undefined ||
                        val.value.promo_period === null)
                ) {
                    // Add the default promoAd
                    promoAds.push(
                        <PromoAd key={itemIndex} promoArrayEl={val} />,
                    );
                    isDefaultAdAdded = true;
                }
            }
        });
    });

    return promoAds;
};
