import { Store } from "@reduxjs/toolkit";

import { IQueryParams } from "../../api/retail";
import { isoMiles } from "../../models/nominals";
import { registerCascade } from "../../utils/cascade";
import { preferredLocationSelector } from "../common/selectors";
import { IRootState } from "../reducers.interfaces";
import { Dispatchers } from "./dispatchers";
import { Loaders, StoreLocationProvider } from "./loaders";
import { storeFilterSelector } from "./selectors";

type IRootRetailState = Pick<IRootState, "retail" | "common">;

export const registerCascades = (
    store: Store<IRootRetailState>,
    loadClosestStores?: StoreLocationProvider,
    extraStoreFilters?: Partial<IQueryParams>,
) => {
    const loaders = new Loaders();
    const dispatchers = new Dispatchers(store.dispatch);
    const _loadClosestStores = loadClosestStores ?? loaders.loadClosestStores;

    // Load Stores when location changes
    registerCascade(store, {
        debounce: 500,

        transformState: (state) => {
            return state;
        },

        getKey: (state) => {
            // prefer entered location zip over detected location zip
            const location = preferredLocationSelector(state);
            // storeFilters for updating in every filter change
            const storeFilters = storeFilterSelector(state);
            return `${JSON.stringify(location)} ${JSON.stringify(
                storeFilters,
            )}`;
        },

        doCascade: async (key, state) => {
            if (key === state.retail.storesLoadedKey) {
                return true;
            }
            const location = preferredLocationSelector(state);
            if (!location) {
                return false;
            }
            // Prepare and spread current store filters
            const storeFilters = storeFilterSelector(state);
            const currentDistance = isoMiles.unwrap(storeFilters.distance);
            const storeFiltersParams: Partial<IQueryParams> = {
                ...storeFilters,
                distance: currentDistance,
            };
            // Load and sort stores
            const stores = (
                await _loadClosestStores(location, {
                    ...extraStoreFilters,
                    ...storeFiltersParams,
                })
            ).sort((a, b) => {
                if (a.distance === b.distance) {
                    return 0;
                }
                return a.distance > b.distance ? 1 : -1;
            });
            // Update Redux data
            dispatchers.setStores(key, stores);
            return true;
        },
    });
};
