import { initPromoCalloutMessages } from "tsi-common-react/src/utils/domManipulation";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

const registerEventListeners_Accordion = () => {
    // Accordion
    Array.from(
        document.querySelectorAll<HTMLButtonElement>(".accordion"),
    ).forEach((elem) => {
        elem.addEventListener("click", function (event) {
            if ((event.target as HTMLButtonElement).getAttribute("href")) {
                return;
            }
            event.preventDefault();
            this.classList.toggle("accordion--is-active");
            const ariaExpanded = this.getAttribute("aria-expanded");
            if (ariaExpanded === "true" || ariaExpanded === "false") {
                this.setAttribute(
                    "aria-expanded",
                    ariaExpanded === "true" ? "false" : "true",
                );
            }

            const parentElement = this.closest("li") || this.parentNode;
            if (!parentElement) {
                return;
            }

            Array.from(parentElement.children)
                .filter((e) => {
                    return e.classList.contains("accordion-target");
                })
                .forEach((e) => {
                    e.classList.toggle("accordion-target--is-active");
                });
        });
    });

    // If on the order page...
    Array.from(
        document.querySelectorAll<HTMLElement>(
            ".order-summary-details__info-column--order-details",
        ),
    ).forEach((elem) => {
        elem.addEventListener("click", function () {
            if (!this.parentNode) {
                return;
            }

            Array.from(this.parentNode.children)
                .filter((e) => {
                    return e.classList.contains(
                        "order-summary-details__info-column--order-details",
                    );
                })
                .forEach((e) => {
                    if (!e.parentNode) {
                        return;
                    }
                    const detailHeader = e.parentNode.parentNode as HTMLElement;
                    detailHeader.classList.toggle(
                        "order-summary-details__header--active",
                    );
                });

            this.classList.toggle(
                "order-summary-details__info-column--order-details--close",
            );
            const ariaExpanded = this.getAttribute("aria-expanded");
            if (ariaExpanded === "true" || ariaExpanded === "false") {
                this.setAttribute(
                    "aria-expanded",
                    ariaExpanded === "true" ? "false" : "true",
                );
            }
        });
    });
};

// Disable the accordion functionality of
// Rich Navigation Link Collections
const rich_navigations = document.querySelectorAll<HTMLElement>(
    "nav.rich-navigation",
);
if (rich_navigations) {
    Array.from(rich_navigations).forEach((rich_navigation) => {
        Array.from(
            rich_navigation.querySelectorAll(
                ".rich-navigation--link-collection",
            ),
        ).forEach((linkCollection) => {
            linkCollection
                .querySelector<HTMLElement>("button")
                ?.removeAttribute("aria-expanded");
            linkCollection
                .querySelector<HTMLElement>(".collection")
                ?.removeAttribute("aria-hidden");
        });
    });
}
// Mobile rich nav sticky on scroll
const registerEventListeners_StickyNavToggle = () => {
    const isProductComparePage =
        document.querySelector<HTMLElement>("body")?.dataset.trackPageType ===
        "ProductComparePage";

    const isCheckoutPage =
        document.querySelector<HTMLElement>("body")?.dataset.viewName ===
        "checkout:index";
    if (isCheckoutPage) {
        return;
    }
    if (rich_navigations && !isProductComparePage) {
        const headerElem = document.querySelector<HTMLElement>("header");
        let lastScrollTop = 0;

        window.addEventListener("scroll", () => {
            const st = window.pageYOffset;

            if (headerElem) {
                if (
                    st > headerElem.getBoundingClientRect().height &&
                    st > lastScrollTop
                ) {
                    headerElem?.classList.add("sticky");
                } else {
                    headerElem?.classList.remove("sticky");
                }
                lastScrollTop = st <= 0 ? 0 : st;
            }
        });
    }
};

// Dynamic Upsell Info Modal Panel Offset CSS Variable
const setUpsellInfoModalPanelOffset = (element: HTMLElement) => {
    const offsetValue = element.getBoundingClientRect().top;
    let offset = `${offsetValue}px`;
    if (offsetValue < 0) {
        offset = "0px";
    }
    document.documentElement.style.setProperty(
        "--configurator--upsell--panel-offset",
        offset,
    );
};

const checkUpsellInfoModalPanelOffset = () => {
    const content = document.getElementById("main-content");
    if (content) {
        setUpsellInfoModalPanelOffset(content);
    }
    window.addEventListener("scroll", () => {
        if (content) {
            setUpsellInfoModalPanelOffset(content);
        }
    });
};

// Product Hero Section Sticky Offset
const setProductHeroSectionOffset = () => {
    const content = document.getElementById("main-content");
    if (content) {
        const offset = `${content.getBoundingClientRect().top}px`;
        document.documentElement.style.setProperty(
            "--product-hero--sticky-offset",
            offset,
        );
    }
};

// Register functions after body loads
onDOMContentLoaded.on(() => {
    registerEventListeners_Accordion();
    registerEventListeners_StickyNavToggle();
    checkUpsellInfoModalPanelOffset();
    setProductHeroSectionOffset();
    initPromoCalloutMessages();
});
